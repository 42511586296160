
function validarValor(e, organizacao, errors, setOrganizacao, setErrors) {
  const rawValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
  const formattedValue = (rawValue / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  setOrganizacao({ ...organizacao, valor: `BRL ${formattedValue}` });

  if (rawValue < 100) {
    setErrors({ ...errors, valor: "Digite um valor válido", geral: "" });
  } else {
    setErrors({ ...errors, valor: "", geral: "" });
  }
}

const ValidacaoPrecoAssinatura = {
  validarValor,
}

export default ValidacaoPrecoAssinatura;