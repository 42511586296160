import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import { useState } from "react";
import GrupoModal from "../GruposModal";
import InputSearch from "../../../components/InputSearch";
import CardGrupo from "../../../components/Cards/CardGrupo";
import ControllerGrupos from "./controller";
import { useEffect } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import arvore_image from "../../../../assets/arvore.png";
import add_icone from "../../../../assets/icons/add.png";
import NovoGrupoModal from "../NovoGrupoModal";
import Loading from "../../../components/Loading";

function Home({usuario, errors, setErrors}){
  const [grupoSelected, setGrupoSelected] = useState();
  const [showNovoGrupoModal, setShowNovoGrupoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ meusGrupos, setMeusGrupos ] = useState();
  const [ grupos, setGrupos ] = useState();
  const [ pageMeusGrupos, setPageMeusGrupos ] = useState(1);
  const [ page, setPage ] = useState(1);
  const [ search, setSearch ] = useState('');
  const [typingTimeout, setTypingTimeout] = useState();

  const controllerGrupos =  new ControllerGrupos(usuario, errors, setErrors, setLoading, page, setPage, search, setSearch, grupos, setGrupos, meusGrupos, setMeusGrupos, pageMeusGrupos, setPageMeusGrupos);

  useEffect(()=>{
    controllerGrupos.findMeusGrupos(meusGrupos, setMeusGrupos, pageMeusGrupos);
    controllerGrupos.findAllGrupos(grupos, setGrupos, page);
  }, [page, usuario, search]);

  return(
    <SideBar>
      <Main>
        {loading && <Loading/>}
        {grupoSelected && <GrupoModal grupo={grupoSelected} closeModal={()=>setGrupoSelected(null)} handleParticipar={()=>controllerGrupos.participar(grupoSelected, setGrupoSelected)} />}
        { showNovoGrupoModal && <NovoGrupoModal onClose={()=>setShowNovoGrupoModal(false)} atualizarGrupos={()=>setPage(page === 1 ? 0 : 1)}/> }
        <div className="content">
          
          <section>
            <div className="top">
              <div className="title">
                <h2>Grupos</h2>
                <span>Acesse seus grupos preferidos e conheça novos plantadores.</span>
              </div>
              <InputSearch text="Pesquisar grupo" onChangeCapture={(e) => controllerGrupos.handleSearch(e, typingTimeout, setTypingTimeout)}/>
            </div>
          </section>

          <button type="button" onClick={()=>setShowNovoGrupoModal(true)}>
            <img src={add_icone} alt="Icone de adicionar"/>
            Criar um novo grupo
          </button>

          { meusGrupos?.rows?.length > 0 &&
            <section>
              <h2>Meus Grupos</h2>
              <div className="painel">
                {
                  meusGrupos?.rows?.map((grupo) => (
                    <CardGrupo grupo={grupo} link={grupo?.id}/>      
                  ))
                }
              </div>
            </section>
          }

          { grupos?.rows?.length > 0 &&
            <section>
              <h2>Grupos para conhecer</h2>
              <div className="painel">
                {
                  grupos?.rows?.map(grupo => (
                    <CardGrupo grupo={grupo} onClick={()=>setGrupoSelected(grupo)}/>      
                  ))
                }
              </div>
            </section>
          }

          {
            (grupos?.rows?.length === 0 && meusGrupos?.rows?.length === 0) &&
            <div className="painel-empty">
              <img src={arvore_image} alt="Icone de uma árvore"/>
              <span>Ainda não há nada aqui. Seja o primeiro a criar um grupo...</span>
            </div>
          }

        </div>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);