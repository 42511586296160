import { Content, Main } from "./styled";
import warning from "../../../assets/icons/warning.svg";

function DeleteDialog({titulo, subtitulo, onDelete, onClose, error, textButton}){
  return(
    <Main>
      <Content>
        <div className="top">
          <img src={warning} alt="Icone de Carregamento" className="warning"/>         
          <h3>{titulo}</h3>
        </div>
        
        <span>{subtitulo}</span>
        <span className="error">{error}</span>
        <div className="bottom">
          <button type="button" onClick={onDelete}>{textButton || 'Deletar'}</button>

          <div className="secondary-button">
            <button type="button" onClick={onClose}>Cancelar</button>
          </div>
        </div>
        
    
      </Content>
    </Main>
  );
}

export default DeleteDialog;