import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import Select from "../../../Select";
import { useState } from "react";
import { useEffect } from "react";
import PlanoAssinatura from '../../../../../models/PlanoAssinatura';
import ValidacaoPrecoAssinatura from "./validacao";
import CustomHandle from "../../../../../utils/CustomHandle";
import AlertDialog from "../../../AlertDialog";

function CardPrecoAssinatura() {

  const [planoAssinatura, setPlanoAssinatura] = useState({});
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  async function getPlanoAssinatura() {
    var response = await PlanoAssinatura.findAll();
    var newValue =`BRL ${(parseFloat(response.data.rows[0].valor).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    }))}`;
    setPlanoAssinatura({...response.data.rows[0], valor: newValue});
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const new_value = parseFloat(planoAssinatura.valor.replace(/\D/g, ""))/100;
    const submitAction = async () => await PlanoAssinatura.create({...planoAssinatura, valor: new_value});
    const onSuccess = () => {
      setShowSuccess(true);
    }
    await CustomHandle.submitForm('form-plano-assinatura', submitAction, errors, setErrors, setShowLoading, onSuccess);

  }

  useEffect(() => {
    getPlanoAssinatura();
  }, []);


  return (
    <Card className="card-gerenciamento">
      <Formulario className="form-plano-assinatura" id="form-plano-assinatura">
        {showSuccess &&
          <AlertDialog
            width={320}
            titulo={`Preço atualizado com sucesso!`}
            buttonText={`Voltar`}
            buttonOnClick={()=>{setShowSuccess(false)}}
          />
        }
        <Content>
          <h3>Preço da assinatura Pro</h3>
          <h4>Gerencie o preço da assinatura mensal.</h4>
          <Formulario>
            <div className="form-group">
              <label>Preço atual</label>
              <input
                required
                value={planoAssinatura?.valor || ""}
                type="text"
                name="valor"
                id="valor"
                placeholder={"Digite o valor do plano"}
                onChange={(e) => ValidacaoPrecoAssinatura.validarValor(e, planoAssinatura, errors, setPlanoAssinatura, setErrors)}
              />
              <span className="error">{errors?.valor}</span>
            </div>

            <div className="form-group">
              <button className="edit-button" type="button" disabled={showLoading} onClick={handleSubmit}>{showLoading ? 'Editando...' : 'Editar preço'}</button>
            </div>

          </Formulario>
        </Content>
      </Formulario>
    </Card>
  );
}

export default CardPrecoAssinatura;

