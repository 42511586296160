import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import Select from "../../../Select";
import { useState } from "react";
import { useEffect } from "react";
import Organizacao from '../../../../../models/Organizacao';
import ValidacaoFormularioApoiador from "./validacao";
import CustomHandle from "../../../../../utils/CustomHandle";
import AlertDialog from "../../../AlertDialog";

function CardFormularioApoiador() {

  const [organizacao, setOrganizacao] = useState({});
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  async function getOrganizacao() {
    var response = await Organizacao.findPlantioBrasil();
    setOrganizacao(response.data);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const submitAction = async () => await Organizacao.updateForm(organizacao.id, organizacao.formulario_apoiador);
    const onSuccess = () => {
      setShowSuccess(true);
    }
    await CustomHandle.submitForm('form-formulario-apoiador', submitAction, errors, setErrors, setShowLoading, onSuccess);

  }

  useEffect(() => {
    getOrganizacao();
  }, []);


  return (
    <Card className="card-gerenciamento">
      <Formulario className="form-formulario-apoiador" id="form-formulario-apoiador">
        {showSuccess &&
          <AlertDialog
            width={320}
            titulo={`Link patrocinador atualizado com sucesso!`}
            buttonText={`Voltar`}
            buttonOnClick={()=>{setShowSuccess(false)}}
          />
        }
        <Content>
          <h3>Formulário do Apoiador</h3>
          <h4>Link para se tornar um apoiador.</h4>
          <Formulario>
            <div className="form-group">
              <label>Link do formulário</label>
              <input
                required
                value={organizacao.formulario_apoiador || ""}
                type="text"
                name="formulario_apoiador"
                id="formulario_apoiador"
                placeholder={"Digite o link do formulário"}
                onChange={(e) => ValidacaoFormularioApoiador.validarLink(e, organizacao, errors, setOrganizacao, setErrors)}
              />
              <span className="error">{errors?.formulario_apoiador}</span>
            </div>

            <div className="form-group">
              <button className="edit-button" type="button" disabled={showLoading} onClick={handleSubmit}>{showLoading ? 'Editando...' : 'Editar link'}</button>
            </div>

          </Formulario>
        </Content>
      </Formulario>
    </Card>
  );
}

export default CardFormularioApoiador;

