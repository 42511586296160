import Formulario from "../../../components/Formulario";
import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import lixeira_icon from "../../../../assets/icons/lixeira.svg";
import search_icon from "../../../../assets/icons/seach.svg";
import plus_icon from "../../../../assets/icons/plus.svg";
import location_icon from "../../../../assets/icons/location.svg";
import arrow_back_icon from "../../../../assets/icons/arrow-back.svg";
import Map from "../../../components/Map";
import { Content } from "./styled";
import PlacesAutocomplete from "react-places-autocomplete";
import { useState } from "react";
import { useEffect } from "react";
import Select from "../../../components/Select";
import ControllerPlantar from "./controller";
import { tipoPlantio, tipoProPlantio } from "../../../../utils/data";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";
import Plantio from "../../../../models/Plantio";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteMidiaDialog from "../../../components/DeleteMidiaDialog";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { ModalAssinatura } from "../../../components/ModalAssinatura";

function Home({ usuario, errors, setErrors }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { plantio_id } = useParams();


  const [mapCenter, setMapCenter] = useState({ lat: -20.3197, lng: -40.3385 });
  const [forceCenter, setForceCenter] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [plantio, setPlantio] = useState({ ...Plantio.model });
  const [especies, setEspecies] = useState([]);
  const [nomeEspecie, setNomeEspecie] = useState('');
  const [midiasFile, setMidiasFile] = useState([]);
  const [midiaDelete, setMidiaDelete] = useState(null);
  const [atualizarPlantio, setAtualizarPlantio] = useState(false);
  const [showModalPro, setShowModalPro] = useState(false);

  const controllerPlantar = new ControllerPlantar(plantio, errors, especies, setShowModalPro, midiasFile, setMidiasFile, setPlantio, setErrors, setLoading, setNomeEspecie, setShowSuccess);

  console.log(plantio.especies)
  useEffect(() => {
    controllerPlantar.findAllEspecies(setEspecies);
    controllerPlantar.findPlantio(plantio_id);
    controllerPlantar.getLoacation(setMapCenter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atualizarPlantio, location]);



  useEffect(() => {
    if (plantio.tipo_categoria) {
      setPlantio({
        ...plantio,
        endereco: '',
        coordenadas: [],
        especies: []
      });

      setNomeEspecie('');
    }

  }, [plantio.tipo_categoria]);


  return (
    <SideBar>
      <Main>
        {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"} />}

        {showSuccess &&
          <AlertDialog
            titulo={`Plantio ${plantio.id ? 'atualizado' : 'cadastrado'} com sucesso!`}
            buttonText={"Ver plantios"}
            buttonOnClick={() => navigate('/minhas-arvores')}
            secondaryButtonText={plantio.id ? "Continuar editando o plantio" : "Cadastrar novo plantio"}
            secondaryButtonOnclick={plantio.id ? () => setShowSuccess(false) : () => controllerPlantar.limparCampos()}
          />
        }

        {showModalPro &&
          <ModalAssinatura onClose={() => setShowModalPro(false)} />
        }


        {midiaDelete && <DeleteMidiaDialog onDelete={() => controllerPlantar.handleRemoveMidia(midiaDelete, setMidiaDelete)} onClose={() => { setMidiaDelete(null) }} />}

        <Content>
          <section className="left-section">

            {plantio.id &&
              <button className="back" onClick={() => navigate('/minhas-arvores/')}>
                <img src={arrow_back_icon} alt="Icone de voltar" />
                <span> Voltar </span>
              </button>
            }

            <div className="top">
              <h2>Registre sua <span className="primary">Árvore</span></h2>
              <span>Por aqui você pode registrar seu plantio com todos os detalhes que desejar.</span>
            </div>

            <div className="form">
              <Formulario id="form-plantar">
                <div className="form-col">

                  <div className="form-group">
                    <label htmlFor="tipo">Individual ou Profissional</label>
                    <select disabled={plantio.id} id="tipo" name="tipo" placeholder="Individual ou Profissional" required
                      onChange={(e) => controllerPlantar.validarTipoPro(e, usuario.tipo)} className={errors.tipoPro && 'input-error'} value={plantio.tipo_categoria}
                    >
                      <option disabled value={''}>Individual ou Profissional</option>
                      {
                        tipoProPlantio.map(tipo => {
                          return <option key={`tipo-plantio-${tipo}`} value={tipo}>{tipo}</option>
                        })
                      }
                    </select>
                    <span className="error">{errors.tipoPro}</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="titulo">{`Titulo ${plantio.tipo_categoria === 'Profissional' ? 'da área' : 'do Plantio'}`}</label>
                    <input disabled={!plantio.tipo_categoria} type="text" id="titulo" name="titulo" placeholder={`Insira o titulo ${plantio.tipo_categoria === 'Profissional' ? 'da área' : 'do Plantio'}`} required
                      value={plantio.titulo} onChange={(e) => controllerPlantar.validarTitulo(e)} className={errors.titulo && 'input-error'}
                    />
                    <span className="error">{errors.titulo}</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="endereco">{`Localizão ${plantio.tipo_categoria === 'Profissional' ? 'da área' : 'do Plantio'}`}</label>
                    <PlacesAutocomplete value={plantio.endereco} onChange={(e) => controllerPlantar.validarLocalizacao(e)} onSelect={(e) => controllerPlantar.validarSelectLocalizacao(e, setForceCenter)}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        return <div>
                          <input
                            {...getInputProps({
                              placeholder: `Insira a localização ${plantio.tipo_categoria === 'Profissional' ? 'da área' : 'do Plantio'}`,
                              className: `input-prefix ${errors.endereco && 'input-error'}`,
                              name: "endereco",
                              required: true,
                              id: "endereco",
                              disabled: !plantio.tipo_categoria
                            })}
                          />

                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Carregando...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                              return (
                                <div
                                  key={`sugestions-${suggestion.index}`}
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <img src={location_icon} alt="Icone de localização" />
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      }}
                    </PlacesAutocomplete>
                    <img className="prefix" src={search_icon} alt="Icone de busca" />
                    <div className="coordenadas-list">
                      {plantio.coordenadas?.map((coordenada, index) => {
                        return coordenada.end ?
                          <></> :
                          <div className="coordenadas">Coordenadas {index + 1}: <span>{parseFloat(coordenada.lat)}, {parseFloat(coordenada.lng)}</span></div>
                      })}
                    </div>
                    <span className="error">{errors.endereco}</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="especie">{`${plantio.tipo_categoria === 'Profissional' ? 'Espécies' : 'Espécie'}`}</label>
                    <Select
                      id="especies"
                      name="especies"
                      placeholder={`Selecione ${plantio.tipo_categoria === 'Profissional' ? 'as espécies' : 'uma espécie'}`}
                      required
                      autoComplete="off"
                      value={nomeEspecie}
                      onChange={(e) => controllerPlantar.filtrarEspecies(e, setEspecies)}
                      className={`input-prefix ${errors.especies && 'input-error'}`}
                      options={especies.rows}
                      onClickOption={(especie) => controllerPlantar.onClickSelectEspecie(especie)}
                      showOptions={showOptions}
                      setShowOptions={setShowOptions}
                      disabled={!plantio.tipo_categoria}
                      selecteds={plantio.especies}
                      closeOnSelect={plantio.tipo_categoria !== 'Profissional'}
                    />
                    <span className="error">{errors.especies}</span>
                    <div className="especies-container">
                      {plantio.especies.map((especieItem, index) => (
                        <div className="especie-item">
                          <span className="nome-especie">{`${especieItem?.especie?.nome_comum || especieItem?.especie?.nome_cientifico}`}</span>
                          <div className="especies-count">
                            <span onClick={() => controllerPlantar.handleRemoveCountMudas(index)}> - </span>
                            <input value={especieItem.numero_mudas} onChange={(e) => controllerPlantar.handleChangeCountMudas(e, index)} />
                            <span onClick={() => controllerPlantar.handleAddCountMudas(index)}> + </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="tipo">Tipo de plantio</label>
                    <select disabled={!plantio.tipo_categoria} id="tipo" name="tipo" placeholder="Selecione o tipo de plantio" required
                      onChange={(e) => controllerPlantar.validarTipo(e)} className={errors.tipo && 'input-error'} value={plantio.tipo}
                    >
                      <option disabled value={''}>Selecione o tipo de plantio</option>
                      {
                        tipoPlantio.map(tipo => {
                          return <option key={`tipo-plantio-${tipo}`} value={tipo}>{tipo}</option>
                        })
                      }
                    </select>
                    <span className="error">{errors.tipo}</span>
                  </div>
                  {
                    (plantio.tipo && plantio.tipo !== 'Pessoal') &&
                    <div className="form-group">
                      <label htmlFor="nome_organizacao">Nome da {plantio.tipo === "Organizacional" ? 'organização' : 'empresa'}</label>
                      <input type="text" id="nome_organizacao" name="nome_organizacao" placeholder="Insira o nome da organização" required
                        value={plantio.nome_organizacao} onChange={(e) => controllerPlantar.validarOrganizacao(e)} className={errors.nome_organizacao && 'input-error'}
                      />
                      <span className="error">{errors.nome_organizacao}</span>
                    </div>
                  }


                  <div className="form-group">
                    <label>Adicionar fotos/videos</label>
                    <input disabled={!plantio.tipo_categoria} id="midias" type="file" name="midias" accept="image/*,video/mp4" multiple required={!plantio.id} onChange={(e) => controllerPlantar.handleChangeMidia(e)} />
                    <div className="midias">
                      {
                        plantio.midias.map(midia => (
                          <label key={`midia-plantio-${midia.id}`}>
                            <div className="image">
                              {midia.tipo === 'Imagem' ? (
                                <img className="preview-image" src={midia.path} alt="Preview" />
                              ) : (
                                <video className="preview-video" src={midia.path} controls />
                              )}
                              <div className="blur"
                                onClick={() => controllerPlantar.handleDeleteMidia(midia, setMidiaDelete)}
                              >
                                <div className="blur-bg"></div>
                                <img src={lixeira_icon} alt="Icone de Lixeira" />
                              </div>

                            </div>
                          </label>
                        ))
                      }

                      {
                        midiasFile.map((file, index) => (
                          <label key={`midias-added-${index}`}>
                            <div className="image">
                              {file.type.includes('image') ? (
                                <img className="preview-image" src={URL.createObjectURL(file)} alt="Preview" />
                              ) : (
                                <video className="preview-video" src={URL.createObjectURL(file)} controls />
                              )}
                              <div className="blur"
                                onClick={() => controllerPlantar.handleDeleteMidia(file, setMidiaDelete)}
                              >
                                <div className="blur-bg"></div>
                                <img src={lixeira_icon} alt="Icone de Lixeira" />
                              </div>

                            </div>
                          </label>
                        )
                        )}

                      {controllerPlantar.validarMidias(plantio.midias, midiasFile, 4) &&
                        <label htmlFor="midias">
                          <div className={`image ${errors.midias && 'image-error'}`} onClick={() => setErrors({ ...errors, midias: "" })}>
                            <img className="plus-icon" src={plus_icon} alt="Iocne de adicionar" />
                          </div>

                        </label>
                      }

                    </div>
                    <span className="error">{errors.midias}</span>
                  </div>
                </div>

                <div className="form-group">
                  <button type="submit" onClick={(e) => controllerPlantar.handleSubmit(e, usuario.id, setAtualizarPlantio, atualizarPlantio, !!plantio.id)}>{plantio.id ? "Atualizar" : "Registrar"} Plantio</button>
                  <span className="error">{errors.geral}</span>
                </div>
              </Formulario>
            </div>

          </section>

          <section className="right-section">
            <Map
              isPro={plantio.tipo_categoria === 'Profissional'}
              isEndArea={!!plantio_id}
              markers={plantio.coordenadas}
              mapCenter={{ lat: parseFloat(plantio.lat) || mapCenter.lat, lng: parseFloat(plantio.lng) || mapCenter.lng }}
              onClick={(e) => controllerPlantar.validarClickLocalizacao(e, plantio.tipo_categoria === 'Profissional')}
              onMarkerDragEnd={(markerIndex, latLng) => controllerPlantar.handleOnDrag(markerIndex, latLng)}
              onCloseArea={() => controllerPlantar.handleCloseArea()}
              forceCenter={forceCenter}
              plantio={!!plantio_id}
            />
          </section>
        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);