import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import Map from "../../../components/Map";
import { Content } from "./styled";
import { useState } from "react";
import Loading from "../../../components/Loading";
import arvores_icone from "../../../../assets/icons/arvores.svg";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import especie_icone from "../../../../assets/icons/especie.svg";
import data_icone from "../../../../assets/icons/data.svg";
import tipo_icone from "../../../../assets/icons/profile.svg";
import arrow_left_icon from "../../../../assets/icons/arrow-left.svg";
import arrow_rigth_icon from "../../../../assets/icons/arrow-right.svg";
import marker_icone from "../../../../assets/icons/pin-arvore.svg";
import marker_pro_icone from "../../../../assets/pin_arvore_pro.png";
import { formatData } from "../../../../utils/conversoes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ControllerMapa from "./controller";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";

function Home({ usuario, errors, setErrors }) {
  const [loading, setLoading] = useState(false);
  const [plantio, setPlantio] = useState({ especie: {}, midias: [] });
  const [plantios, setPlantios] = useState({ rows: [] });
  const [mapCenter, setMapCenter] = useState({ lat: -20.3197, lng: -40.3385 });
  const selecione_text = "Selecione um plantio para ver detalhes.";
  const navigate = useNavigate();

  const controllerMapa = new ControllerMapa(errors, setErrors, setLoading, setPlantios);

  useEffect(() => {
    controllerMapa.findPlantios(usuario.id);
    controllerMapa.getLoacation(setMapCenter);
    // eslint-disable-next-line
  }, [usuario]);

  return (
    <SideBar>
      <Main>
        {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos carregando suas informações"} />}

        <Content>
          <section className="left-section">

            <div className="top">
              <h2>Mapa</h2>
              <span>Clique em um plantio do mapa para visualizar informações</span>
            </div>

            <div className="painel">
              <div className="item">
                <h4>Titulo</h4>
                <div className="info">
                  <img src={arvores_icone} alt="Icone de árvores" />
                  <span>{plantio.titulo || selecione_text}</span>
                </div>
              </div>

              <div className="item">
                <h4>Localização</h4>
                <div className="info">
                  <img src={localizacao_icone} alt="Icone de localização" />
                  <span>{plantio.endereco || selecione_text}</span>
                </div>
              </div>
              {plantio.coordenadas?.map((plantio, index) => (
                <div className="coordenadas">Coordenada {index + 1}: <span>{parseFloat(plantio.lat)}, {parseFloat(plantio.lng)}</span></div>
              ))}

              <div className="item">
                <h4>Espécie</h4>
                <div className="info">
                  <img src={especie_icone} alt="Icone de especie" />
                  {!plantio.especies ?
                    <span>{selecione_text}</span> :
                    <div className="especies-list">
                      {plantio.especies.map(especieItem => (
                        <span>{`${especieItem.especie.nome_cientifico} (${especieItem.especie.nome_comum}) - ${especieItem.numero_mudas} mudas`}</span>
                      ))

                      }
                    </div>
                  }

                </div>
              </div>

              <div className="item">
                <h4>Data do Plantio</h4>
                <div className="info">
                  <img src={data_icone} alt="Icone de calendario" />
                  <span>{formatData(plantio.data) || selecione_text}</span>
                </div>
              </div>

              <div className="item">
                <h4>Tipo de Plantio</h4>
                <div className="info">
                  <img src={tipo_icone} alt="Icone de tipo de plantio" />
                  <span>{plantio.tipo || selecione_text}</span>
                </div>
              </div>

              {plantio.midias.length > 0 &&
                <div className="item-midias">
                  <h4>Fotos e vídeos do plantio</h4>

                  <section className="item-images">
                    <div className="gallery">

                      <div className="images">
                        {
                          plantio?.midias?.map(midia => {
                            return (
                              <div key={`midia-${midia.id}`} className="image">
                                {
                                  midia.tipo === 'Imagem' ?
                                    <img src={`${midia.path}`} alt={`Imagem ${midia.id} do plantio ${plantio.id}`} /> :
                                    <video src={`${midia.path}`} title={`Video ${midia.id} do plantio ${plantio.id}`} />
                                }
                              </div>
                            )
                          })
                        }


                      </div>

                      <div className="previus">
                        <button type="button" onClick={() => controllerMapa.previousImage()}>
                          <img src={arrow_left_icon} alt="Icone de anterior" />
                        </button>
                      </div>
                      <div className="next" onClick={() => controllerMapa.nextImage()}>
                        <button type="button">
                          <img src={arrow_rigth_icon} alt="Icone de próximo" />
                        </button>
                      </div>

                    </div>
                  </section>

                </div>
              }

              {
                plantio.id &&
                <button className="atualizar-button" type="button" onClick={() => navigate(`/minhas-arvores/atualizar/${plantio.id}`)}>Atualizar plantio</button>
              }

            </div>

          </section>

          <section className="right-section">
            <Map
              markers={plantios.rows.map(plantio => (
                { ...plantio, lat: plantio.coordenadas[0]?.lat, lng: plantio.coordenadas[0]?.lng }
              ))}
              marker_icon={plantio.tipo_categoria === "Profissional" ? marker_pro_icone : marker_icone}
              onClickMarker={(plantio) => setPlantio(plantio)}
              mapCenter={{ lat: parseFloat(plantio.lat) || mapCenter.lat, lng: parseFloat(plantio.lng) || mapCenter.lng }}
              forceCenter={{lat : plantios.rows[0]?.coordenadas[0]?.lat, lng:plantios.rows[0]?.coordenadas[0]?.lng }}
            />
          </section>
        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);