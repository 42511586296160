import { geocodeByAddress } from "react-places-autocomplete";
import { tipoPlantio, tipoProPlantio } from "../../../../utils/data";
import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";
import Especie from "../../../../models/Especie";
import { convertToBase64 } from "../../../../utils/conversoes";

class ControllerPlantar {
  constructor(plantio, errors, especies, setShowModalPro, midiasFile, setMidiasFile, setPlantio, setErrors, setLoading, setNomeEspecie, setShowSuccess) {
    this.plantio = plantio;
    this.errors = errors;
    this.setPlantio = setPlantio;
    this.setErrors = setErrors;
    this.especies = especies;
    this.setLoading = setLoading;
    this.midiasFile = midiasFile;
    this.setNomeEspecie = setNomeEspecie;
    this.setMidiasFile = setMidiasFile;
    this.setShowSuccess = setShowSuccess;
    this.setShowModalPro = setShowModalPro;
  }

  async validarLocalizacao(e) {
    const { plantio, errors, setPlantio, setErrors } = this;
    setPlantio({ ...plantio, endereco: e, lat: null, lng: null });
    if (!plantio.lat || !plantio.lng) {
      setErrors({ ...errors, endereco: "Selecione um endereço válido" });
    } else {
      setErrors({ ...errors, endereco: "" });
    }
  }

  async validarSelectLocalizacao(e, setForceCenter) {
    const { plantio, errors, setPlantio, setErrors } = this;
    const results = await geocodeByAddress(e);
    let lat = results[0].geometry.viewport.getCenter().lat();
    let lng = results[0].geometry.viewport.getCenter().lng();
    setForceCenter({ lat, lng });
    const cidade = results[0].address_components.find(result => result.types.includes("administrative_area_level_2")).long_name;
    const estado = results[0].address_components.find(result => result.types.includes("administrative_area_level_1")).long_name;
    setPlantio({ ...plantio, endereco: e, cidade, estado, coordenadas: [{ lat, lng, posicao: 0 }] });
    setErrors({ ...errors, endereco: "" });
  }

  async validarClickLocalizacao(e, isPro) {

    const { plantio, errors, setPlantio, setErrors } = this;
    const geocoder = new window.google.maps.Geocoder();
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    const { lat: latSearch, lng: lngSearch } = (isPro && plantio.coordenadas[0]) ? plantio.coordenadas[0] : { lat, lng, posicao: 0 };
    if (plantio.coordenadas[0]) {
      const coordenadas = isPro ? [...plantio.coordenadas] : [];
      coordenadas.push({ lat, lng, posicao: coordenadas.length });
      setPlantio({ ...plantio, coordenadas });
    } else {
      geocoder.geocode({ location: { lat: latSearch || lat, lng: lngSearch || lng } }, (results, status) => {
        const coordenadas = isPro ? [...plantio.coordenadas] : [];
        const address = results[0].formatted_address;
        const cidade = results[0].address_components.find(result => result.types.includes("administrative_area_level_2")).long_name;
        const estado = results[0].address_components.find(result => result.types.includes("administrative_area_level_1")).long_name;
        coordenadas.push({ lat, lng, posicao: coordenadas.length });
        setPlantio({ ...plantio, endereco: address, cidade, estado, coordenadas });
        setErrors({ ...errors, endereco: "" });

      });
    }
  }

  handleOnDrag(markerIndex, latLang) {
    const coordenadas = [...this.plantio.coordenadas];
    coordenadas[markerIndex] = { ...coordenadas[markerIndex], lat: latLang.lat(), lng: latLang.lng() };
    this.setPlantio({ ...this.plantio, coordenadas })
  }

  validarEspecie(e) {
    const { errors, setErrors, especies } = this;
    if (especies) {
      const exist = especies.rows.find(
        (especie) =>
          especie?.nome_comum === e.target.value ||
          especie.nome_cientifico === e.target.value
      );
      if (exist) {
        setErrors({ ...errors, especie: "" });
        return;
      }
    }
    setErrors({ ...errors, especie: "Selecione uma espécie válida" });
  }

  validarMudas(e) {
    const { plantio, errors, setPlantio, setErrors } = this;
    setPlantio({ ...plantio, numero_mudas: e.target.value });
    if (e.target.value < 1) {
      setErrors({ ...errors, numero_mudas: "Digite um valor acima de 0" });
    } else {
      setErrors({ ...errors, numero_mudas: "" });
    }
  }

  validarOrganizacao(e) {
    const { plantio, errors, setPlantio, setErrors } = this;
    setPlantio({ ...plantio, nome_organizacao: e.target.value });
    if (e.target.value < 1) {
      setErrors({ ...errors, nome_organizacao: "Digite um nome válido" });
    } else {
      setErrors({ ...errors, nome_organizacao: "" });
    }
  }

  validarTitulo(e) {
    const { plantio, errors, setPlantio, setErrors } = this;
    setPlantio({ ...plantio, titulo: e.target.value });
    if (e.target.value < 1) {
      setErrors({ ...errors, titulo: "Digite um titulo válido" });
    } else {
      setErrors({ ...errors, titulo: "" });
    }
  }

  validarTipo(e) {
    const { plantio, errors, setPlantio, setErrors } = this;
    if (e.target.value === "Pessoal") {
      const new_plantio = { ...plantio };
      delete new_plantio.nome_organizacao;
      new_plantio.tipo = e.target.value;
      setPlantio(new_plantio);
    } else {
      setPlantio({ ...plantio, tipo: e.target.value });
    }

    if (!tipoPlantio.includes(e.target.value)) {
      setErrors({ ...errors, tipo: "Selecione um tipo de plantio válido" });
    } else {
      setErrors({ ...errors, tipo: "" });
    }
  }

  validarTipoPro(e, usuario_tipo) {
    const { plantio, setShowModalPro, errors, setPlantio, setErrors } = this;
    
    //TODO: Verificar se o cliente é pró
    if (e.target.value === "Profissional" && usuario_tipo === "Comum") {
      setShowModalPro(true);
      setPlantio({ ...plantio, tipo_categoria: '' });
    } else {
      setPlantio({ ...plantio, tipo_categoria: e.target.value });
    }


    if (!tipoProPlantio.includes(e.target.value)) {
      setErrors({ ...errors, tipo: "Selecione um tipo de plantio válido" });
    } else {
      setErrors({ ...errors, tipo: "" });
    }
  }

  validarMidias(midias, midiasFile, limit = 5) {
    let totalLength = midias.length + midiasFile.length;
    if (totalLength <= limit) {
      return true;
    }
    return false;
  }

  async findAllEspecies(setEspecies) {
    this.setLoading(true);
    const result = await Especie.findAll();
    const onSuccess = (response) => {
      setEspecies(response);
    }

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }

  getLoacation(setMapCenter) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log("Erro ao obter a posição do usuário:", error);
        }
      );
    }
  }

  async findPlantio(plantio_id) {
    if (plantio_id) {
      document.getElementById('midias').value = null;
      this.setMidiasFile([]);
      this.setLoading(true);

      const result = await Plantio.findById(plantio_id);

      const onSuccess = (response) => {

        this.setNomeEspecie(response.tipo_categoria === "Profissional" ?
          `${response.especies.length} especies selecionadas` :
          `${response.especies[0].especie.nome_cientifico} - ${response.especies[0]?.especie?.nome_comum}`
        );
        this.setPlantio({ ...response});
      };

      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

      this.setLoading(false);
    } else {
      this.limparCampos();
    }
  }

  async filtrarEspecies(e, setEspecies) {
    const searchValue = e.target.value;
    this.setNomeEspecie(searchValue);
    let result = [];

    const onSuccess = (response) => {
      setEspecies(response);
      this.validarEspecie(e, this.errors, this.setErrors, response.rows);
    }

    if (searchValue) {
      result = await Especie.findSearch(searchValue);
    } else {
      result = await Especie.findAll();
    }

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);


  }

  handleDeleteMidia(midia, setMidiaDelete) {
    if (this.plantio.midias.length + this.midiasFile.length <= 1) {
      this.setErrors({ ...this.errors, midias: "Não é possível excluir. O plantio precisa ter pelo menos uma mídia." });
    } else {
      setMidiaDelete(midia);
    }
  }

  async handleRemoveMidia(midiaDelete, setMidiaDelete) {
    let newMidias = [];

    if (!midiaDelete.id) {
      newMidias = this.midiasFile.filter((n_file) => midiaDelete !== n_file);
      this.setMidiasFile(newMidias);
    } else {
      const result = await Plantio.deleteMidia(this.plantio.id, midiaDelete.id);
      const onSuccess = () => {
        newMidias = this.plantio.midias.filter((n_file) => midiaDelete !== n_file);
        this.setPlantio((prevPlantio) => ({ ...prevPlantio, midias: newMidias }));
      };

      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    }

    setMidiaDelete(null);

    if (!this.errors.midias) {
      if (!this.validarMidias(this.plantio.midias, newMidias)) {
        this.setErrors({ ...this.errors, midias: 'Selecione no máximo 5 midias' });
      } else {
        this.setErrors({ ...this.errors, midias: '' });
      }
    }

    if (newMidias.length === 0) {
      document.getElementById('midias').value = null;
    }
  }

  handleChangeMidia(e) {
    const { files: selectedFiles } = e.target;
    const updatedMidias = [...this.midiasFile, ...selectedFiles];

    const isMidiasValid = this.validarMidias(this.plantio.midias, updatedMidias);
    const errorMessage = isMidiasValid ? '' : 'Selecione no máximo 5 midias';

    this.setErrors({ ...this.errors, midias: errorMessage });

    if (isMidiasValid) {
      this.setMidiasFile(updatedMidias);
    }
  }

  handleAddCountMudas(index) {
    const especies = [...this.plantio.especies];
    especies[index].numero_mudas += 1;
    this.setPlantio({ ...this.plantio, especies });
  }

  handleRemoveCountMudas(index) {
    const especies = [...this.plantio.especies];
    if (especies[index].numero_mudas > 1) {
      especies[index].numero_mudas -= 1;
      this.setPlantio({ ...this.plantio, especies });
    }
  }

  handleChangeCountMudas(e, index) {
    const especies = [...this.plantio.especies];
    especies[index].numero_mudas = e.target.value > 1 ? e.target.value : 1;
    this.setPlantio({ ...this.plantio, especies });

  }

  onClickSelectEspecie(newEspecie) {
    const { nome_comum, nome_cientifico, id } = newEspecie;
    let especies = [...this.plantio.especies];
    let count = especies.length;

    if (especies.find(especie => especie.especie_id === id)) {
      count -= 1;
      especies = especies.filter(especie => especie.especie_id !== id);
    } else {
      count += 1;
      especies.push({ especie_id: id, numero_mudas: 1, especie: { nome_cientifico, nome_comum } });
    }

    this.setNomeEspecie(
      this.plantio.tipo_categoria === 'Profissional' ?
        `${count} especie(s) selecionadas` :
        `${nome_comum} - ${nome_cientifico}`
    );

    this.setPlantio((prevPlantio) => ({ ...prevPlantio, especies: this.plantio.tipo_categoria === 'Profissional' ? especies : [{ especie_id: id, numero_mudas: 1, especie: { nome_cientifico, nome_comum }  }] }));
    this.setErrors({ ...this.errors, especies: '' });
  }

  tratarPlantio(plantio) {
    let new_plantio = { ...plantio };

    if (new_plantio.tipo === 'Pessoal') {
      delete new_plantio.nome_organizacao;
    }

    if (!new_plantio.data) {
      new_plantio = { ...new_plantio, data: new Date() };
    }

    return new_plantio;
  }

  async addMidiasOnEdit(base64Array, setAtualizarPlantio, atualizarPlantio) {
    const result = await Plantio.addMidias({ plantio_id: this.plantio.id, midias: base64Array });
    const onSuccess = () => {
      setAtualizarPlantio(!atualizarPlantio);
      this.setShowSuccess(true);
    };
    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async handleSubmit(e, usuario_id, setAtualizarPlantio, atualizarPlantio, edit = false) {
    e.preventDefault();

    convertToBase64(this.midiasFile)
      .then(async (base64Array) => {
        const new_plantio = this.tratarPlantio(this.plantio);
        const submitAction = async () => await Plantio.create({ ...new_plantio, usuario_id, midias: base64Array });
        const onSuccess = edit ?
          async () => await this.addMidiasOnEdit(base64Array, setAtualizarPlantio, atualizarPlantio) :
          () => this.setShowSuccess(true);

        await CustomHandle.submitForm('form-plantar', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
      })
      .catch(() => {
        this.setErrors({ ...this.errors, midias: "Ocorreu um erro ao tratar as imagens. Tente novamente" })
      });
  }

  limparCampos() {
    this.setPlantio({ ...Plantio.model });
    this.setNomeEspecie('');
    this.setMidiasFile([]);
    this.setShowSuccess(false);
    document.getElementById('midias').value = null;
  }

}

export default ControllerPlantar;
