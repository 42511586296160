import api from '../services/api';

const Organizacao = {
  updateForm : async function (id, formulario_apoiador) {
    return await api.patch(`organizacoes/id/${id}/formulario-apoiador`, {formulario_apoiador})
    .then(response=>response)
    .catch(error=>error);
  },

  create : async function (data) {
    return await api.post('organizacoes', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (patrocinador_id) {
    return await api.delete(`organizacoes/id/${patrocinador_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('organizacoes/all/1/7')
    .then(response=>response)
    .catch(error=>error);
  },

  findPlantioBrasil : async function () {
    return await api.get('organizacoes/plantio-brasil')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`organizacoes/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Organizacao;
