import api from '../services/api';

const PlanoAssinatura = {
  create : async function (data) {
    return await api.post('plano_assinatura', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (patrocinador_id) {
    return await api.delete(`plano_assinatura/id/${patrocinador_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('plano_assinatura/all/1/1')
    .then(response=>response)
    .catch(error=>error);
  },

  findPlantioBrasil : async function () {
    return await api.get('plano_assinatura/plantio-brasil')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`plano_assinatura/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default PlanoAssinatura;
