
function validarLink(e, organizacao, errors, setOrganizacao, setErrors) {
  setOrganizacao({...organizacao, formulario_apoiador : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, formulario_apoiador : "Digite um link válido", geral: ""})
  }else{
    setErrors({ ...errors, formulario_apoiador : "", geral: ""});
  }
    
}

const ValidacaoFormularioApoiador = {
  validarLink,
}

export default ValidacaoFormularioApoiador;