import { connect } from "react-redux";
import close_icon from "../../../../assets/icons/close.svg";
import Formulario from "../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerAuth from "../../auth/controller";
import { useEffect, useState } from "react";
import { useRef } from "react";
import ControllerConfiguracoes from "../Home/controller";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";
import ControllerConfirmarCadastro from "../../auth/CadastroConfirmacao/controller";

function AlterarEmailModal({ usuario, setUsuario, errors, setErrors, onClose }) {
  const [emailAntigo, setEmailAntigo] = useState(usuario.email);
  const [showCodigoForm, setShowCodigoForm] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const controllerAuth = new ControllerAuth(usuario, errors, setUsuario, setErrors);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [timer, setTimer] = useState(30);

  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);
  const controllerConfirmarCadastro = new ControllerConfirmarCadastro(usuario, setUsuario, inputRefs, setShowSuccess, errors, setErrors, setLoading, setClickCount, setDisabled, setTimer);

  useEffect(() => {
    if (showCodigoForm) {
      let countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }
  }, [clickCount, showCodigoForm]);

  useEffect(() => {
    if (timer <= 0) {
      setDisabled(false);
    }
  }, [timer]);

  return (
    <div className="modal">
      {loading && <Loading />}
      {showSuccess && (
        <AlertDialog
          titulo={`Email alterado com sucesso com sucesso!`}
          buttonText={"Fazer login novamente"}
          buttonOnClick={()=>{window.location.href = '/login'}}
        />
      )}
      <div className="content-modal">
        <div className="top">
          <h3>Alterar Email</h3>
          <button type="button" onClick={() => controllerConfiguracoes.close(onClose, emailAntigo)}>
            <img src={close_icon} alt="Icone de fechar" />
          </button>
        </div>

        <Formulario id="form-alterar-email">
          {!showCodigoForm ?
            <div className="form-group">
              <label htmlFor="email">Novo e-mail</label>
              <input type="text" id="email" name="email" placeholder="Insira seu novo e-mail" required autoComplete="off"
                value={usuario.email} onChange={(e) => controllerAuth.validarEmail(e)} className={errors.email && 'input-error'}
              />
              <span className="error">{errors.email}</span>
            </div> :
            <>
              <span>
                Confirme a alteração preenchendo o código enviado por email para{" "}
                <strong>{usuario.email || "seu email"}</strong>
              </span>

              <div className="form-row">
                {[0, 1, 2, 3].map((index) => (
                  <div className="form-group" key={index}>
                    <label htmlFor={`codigo`}></label>
                    <input
                      className={`input-code ${errors?.codigo && "input-error"}`}
                      type="text"
                      name={`codigo`}
                      id={`codigo`}
                      maxLength="1"
                      required
                      onKeyDown={(e) =>
                        controllerConfirmarCadastro.validarCodigo(e, index)
                      }
                      ref={inputRefs[index]}
                      onPaste={(e) =>
                        controllerConfirmarCadastro.handlePaste(e, inputRefs)
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="form-group">
                <span className="error">{errors?.codigo}</span>
              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="resend-code"
                  disabled={disabled}
                  onClick={() => controllerConfirmarCadastro.handleResendEmail()}
                >
                  {timer > 0
                    ? `Não recebi um código (0:${timer < 10 ? "0" + timer : timer})`
                    : "Não recebi um código"}
                </button>
              </div>
            </>
          }
          <div className="form-group">
            <button type="button"
              onClick={
                showCodigoForm ?
                  (e) => controllerConfiguracoes.confirmarAlterarEmail(e, inputRefs, setEmailAntigo, setShowSuccess) :
                  (e) => controllerConfiguracoes.alterarEmail(e, setShowCodigoForm)
              }
            >
              {showCodigoForm ? 'Salvar' : 'Prosseguir'}
            </button>
          </div>



        </Formulario>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarEmailModal);