import { ModalContent, ModalContentGreen, ModalOverview } from "./styled";
import closeIcon from '../../../assets/icons/close.svg';
import checkIcon from '../../../assets/icons/check-beneficio.svg';
import { useState } from "react";
import { useEffect } from "react";
import PlanoAssinatura from "../../../models/PlanoAssinatura";

export const ModalAssinatura = ({onClose})=>{

    const [planoAssinatura, setPlanoAssinatura] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    async function getPlanoAssinatura() {
        setIsLoading(true);
        var response = await PlanoAssinatura.findAll();
        var newValue =`${(parseFloat(response.data.rows[0].valor).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }))}`;
          setPlanoAssinatura({...response.data.rows[0], valor: newValue});
        setIsLoading(false);
      }

    const beneficios = [
        "Registro de áreas de plantios maiores com diversas coordenadas",
        "Possibilidade de registrar diversas especies por plantio", 
        "Número ilimitado de registros por área",
        "Acesso à Grupos de todo o Brasil"
    ];

    useEffect(()=>{
        getPlanoAssinatura();
    },[])
    
    return(
        <ModalOverview>
            <ModalContent>
                <div className="topModal">
                    <span>Assinatura Profissional</span>
                    <span className="pro">PRO</span>

                    <img className="closeIcon" src={closeIcon} onClick={onClose}/>
                </div>

                <ModalContentGreen>
                    <div className="info">
                        <div className="infoTop">
                            <span className="title">Profissional</span>

                            <p className="description">
                                Ideal para pessoas e empresas que trabalham com plantios de grandes áreas
                            </p>
                        </div>

                        <div className="infoValue">
                            <span className="value">{planoAssinatura.valor}</span>
                            <span className="period">/Mês</span>
                        </div>
                    </div>

                    <div className="beneficios">
                        {beneficios.map(beneficio=>(
                            <div className="beneficio">
                                <img src={checkIcon}/>
                                <span>{beneficio}</span>
                            </div>
                        ))}
                    </div>

                    <button>
                        Assinar agora pelo aplicativo Mobile
                    </button>
                </ModalContentGreen>

                <span className="noInterested" onClick={onClose}>Não tenho interesse</span>

            </ModalContent>
        </ModalOverview>
    );
}