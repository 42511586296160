
import Patrocinador from "../../../../models/Patrocinador";
import Plantio from "../../../../models/Plantio";
import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";
import { convertToBase64 } from "../../../../utils/conversoes";

class ControllerPerfil {
  constructor(usuario, setUsuario, errors, setPlantios, setErrors, setLoading, setPatrocinadores) {
    this.usuario = usuario;
    this.setUsuario = setUsuario;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.setPlantios = setPlantios;
    this.setPatrocinadores = setPatrocinadores;

  }

  async findUsuarioById(){
    const resultUsuario = await Usuario.findById(this.usuario.id);
    const onSuccessUsuario = (response)=>this.setUsuario(response);
    CustomHandle.handleResult(resultUsuario, this.errors, this.setErrors, onSuccessUsuario);
  }

  async findPatrocinadores(){
    const resultPatrocinador = await Patrocinador.findAll(this.usuario.id);
    const onSuccessPatrocinadores = (response)=>this.setPatrocinadores(response['rows']);
    CustomHandle.handleResult(resultPatrocinador, this.errors, this.setErrors, onSuccessPatrocinadores);
  }

  async handleSobre(e, typingTimeout, setTypingTimeout){
    const text = e.target.value;
    this.setUsuario({...this.usuario, sobre : text});

    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(async () => {
      const new_usuario = {...this.usuario};
      delete new_usuario.foto_capa;
      delete new_usuario.foto_perfil;

      const result = await Usuario.create({...new_usuario, sobre : text});
      CustomHandle.handleResult(result, this.errors, this.setErrors, ()=>{});
      
    }, 500);
    setTypingTimeout(newTypingTimeout);
  };

  async handleMidia(e, tipo) {
    const fotoPerfil = e.target.files[0];
    if(fotoPerfil){
      convertToBase64([fotoPerfil])
      .then(async midias=>{
        this.setLoading(true);
        
        let data = { tipo, usuario_id : this.usuario.id, midia: midias[0] };
        const resultAddMidia = await Usuario.addMidia(data);
        const onSuccessAddMidias = async (response) => { await this.findUsuarioById() };
        CustomHandle.handleResult(resultAddMidia, this.errors, this.setErrors, onSuccessAddMidias);
        
        this.setLoading(false);
      });
    }
  }

  async findData(){
    if(this.usuario.id){
        this.setLoading(true);

        const resultPlantios = await Plantio.findSearch(this.usuario.id, 1, ' ');
        const onSuccessPlantios = (response)=>this.setPlantios(response);
        CustomHandle.handleResult(resultPlantios, this.errors, this.setErrors, onSuccessPlantios);
        await this.findUsuarioById();

        this.setLoading(false);
    }
  }
}

export default ControllerPerfil;
