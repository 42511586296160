import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerMinhasArvores {
  constructor(page, setPage, search, errors, setErrors, setSearch, setPlantios, setPlantioSelecionado, setShowModal,  setLoading) {
    this.page = page;
    this.setPage = setPage;
    this.setPlantios = setPlantios;
    this.setPlantioSelecionado = setPlantioSelecionado;
    this.setShowModal = setShowModal;
    this.setSearch = setSearch;
    this.setLoading = setLoading;
    this.search = search;
    this.errors= errors;
    this.setErrors = setErrors;
    this.tabs = [
      {title: "Todos os plantios", pro: false}, 
      {title: "Plantios individuais", value: "individual", pro: false}, 
      {title: "Plantios por área", value: "profissional", pro: true}
    ];
    
  }

  handleDetalhes(plantio){
    this.setPlantioSelecionado(plantio);
    this.setShowModal(true);
  }

  handleSearch(e, typingTimeout, setTypingTimeout){
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      this.setPlantios({});
      this.setSearch(e.target.value);
      if(this.page!==1){
        this.setPage(1);
      }
    }, 500);
    setTypingTimeout(newTypingTimeout);
  };

  async findPlantios(usuario_id, selectedTab){
    if(usuario_id){
      this.setLoading(true);
      const result = await Plantio.findSearch(usuario_id, this.page, this.search, selectedTab.value);
      const onSuccess = (response)=>{
          this.setPlantios(response);
      };

      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

      this.setLoading(false);
    }
    
  }


}

export default ControllerMinhasArvores;
