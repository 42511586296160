import api from '../services/api';

const Grupo = {
  model : {administradores : []},

  create : async function (data) {
    return await api.post('grupos', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (grupo_id) {
    return await api.delete(`grupos/id/${grupo_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function (page, search) {
    return await api.get(`grupos/all/${page}/6?search=${search}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findById : async function (grupo_id) {
    return await api.get(`grupos/id/${grupo_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findSubgruposByGrupoId : async function (grupo_id) {
    return await api.get(`grupos/subgrupos/${grupo_id}/1/50`)
    .then(response=>response)
    .catch(error=>error);
  },


  updateAdministradores : async function (grupo_id, body) {
    return await api.put(`grupos/administradores/${grupo_id}`, body)
    .then(response=>response)
    .catch(error=>error);
  },

  aceitarSolicitacao : async function (solicitacao_id) {
    return await api.post(`grupos/aceitar/${solicitacao_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  recusarSolicitacao : async function (solicitacao_id) {
    return await api.post(`grupos/rejeitar/${solicitacao_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  getParticipantes : async function (grupo_id){
    return await api.get(`grupos/participantes/recentes/${grupo_id}/1/50`)
    .then(response=>response)
    .catch(error=>error);
  },

  getSolicitacoes : async function (grupo_id){
    return await api.get(`grupos/solicitacoes/recentes/${grupo_id}/1/50`)
    .then(response=>response)
    .catch(error=>error);
  },

  findByUsuarioId : async function (usuario_id, page, search) {
    return await api.get(`grupos/usuario/${usuario_id}/${page}/6?search=${search}`)
    .then(response=>response)
    .catch(error=>error);
  },

  participar : async function (usuario_id, grupo_id) {
    return await api.post(`grupos/participar/${usuario_id}/${grupo_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`grupos/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Grupo;
