import { useState } from "react";
import plantio_image from "../../../../assets/plantio.png";
import { Card } from "./styled";
import { useEffect } from "react";
import Organizacao from "../../../../models/Organizacao";

function CardApoiador({ patrocinador }) {
  const [organizacao, setOrganizacao] = useState({});

  async function getOrganizacao() {
    var response = await Organizacao.findPlantioBrasil();
    setOrganizacao(response.data);
  }

  useEffect(()=>{
    getOrganizacao();
  }, []);
  return (
    <Card>
      <a href={`https://${patrocinador?.link.replace('https://','')}`} target="_blank">
        <img src={patrocinador?.foto?.path ?? plantio_image} alt="Imagem do patrocinador" className={patrocinador ? '' : 'img-patrocinador'} />
      </a>
      {!patrocinador &&
        <div className="info">
          <div className="tag">
            Patrocinador
          </div>
          <div className="title">
            {patrocinador?.nome ?? "Sua empresa aqui"}
          </div>
          <div className="details">
            Se torne um patrocinador do projeto e anuncie sua empresa para todos os plantadores do Brasil.
          </div>
          <div>
            <a href={`https://${organizacao?.formulario_apoiador?.replace('https://','')}`} target="_blank" type="button" >Quero ser um patrocinador</a>
          </div>

        </div>
      }
    </Card>
  );
}

export default CardApoiador;