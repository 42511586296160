import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import CardBiomas from "../../../components/Cards/Gerenciamento/CardBiomas";
import CardEspecies from "../../../components/Cards/Gerenciamento/CardEspecies";
import CardUsuarios from "../../../components/Cards/Gerenciamento/CardUsuarios";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import { useState } from "react";
import BiomasModal from "../BiomasModal";
import EspeciesModal from "../EspeciesModal";
import UsuariosModal from "../UsuariosModal";
import PatrocinadoresModal from "../PatrocinadoresModal";
import ApoiadoresModal from "../ApoiadoresModal";
import ListaUsuariosModal from "../ListaUsuariosModal";
import CardPatrocinadores from "../../../components/Cards/Gerenciamento/CardPatrocinadores";
import CardApoiadores from "../../../components/Cards/Gerenciamento/CardApoiadores";
import CardFormularioApoiador from "../../../components/Cards/Gerenciamento/CardFormularioApoiador";
import CardPrecoAssinatura from "../../../components/Cards/Gerenciamento/CardPrecoAssinatura";

function Home(){
  const [showModalBiomas, setShowModalBiomas] = useState(tiposGerenciamentoModal.NONE);
  const [showModalUsuarios, setShowModalUsuarios] = useState(tiposGerenciamentoModal.NONE);
  const [showModalListaUsuarios, setShowModalListaUsuarios] = useState(tiposGerenciamentoModal.NONE);
  const [showModalEspecies, setShowModalEspecies] = useState(tiposGerenciamentoModal.NONE);
  const [showModalPatrocinadores, setShowModalPatrocinadores] = useState(tiposGerenciamentoModal.NONE);
  const [showModalApoiadores, setShowModalApoiadores] = useState(tiposGerenciamentoModal.NONE);
  const [showModalOrganizacoes, setShowModalOrganizacoes] = useState(tiposGerenciamentoModal.NONE);
  const [bioma, setBioma] = useState({});
  const [usuario, setUsuario] = useState({});
  const [patrocinador, setPatrocinador] = useState({});
  const [apoiador, setApoiador] = useState({});
  const [organizacao, setOrganizacao] = useState({});
  const [especie, setEspecie] = useState({nome_comum : '', nome_cientifico: '', biomas : []});
  const [nomeEspecie, setNomeEspecie] = useState("");
  
  return(
    <SideBar>
      <Main>
        <div className="content">
          <section>
            <div className="top">
              <div className="title">
                <h2>Gerenciamento</h2>
                <span>Por aqui você gerencia todas as informações da plataforma.</span>
              </div>
              {/* <InputSearch text="Pesquisar"/> */}
            </div>
          </section>
          
          <section>
            <div className="painel">
            <CardBiomas onCreate={()=>setShowModalBiomas(tiposGerenciamentoModal.CRIAR)} onEdit={(bioma)=>{setShowModalBiomas(tiposGerenciamentoModal.EDITAR); setBioma(bioma)}}/>
            <CardEspecies especie={especie} setEspecie={setEspecie} nomeEspecie={nomeEspecie} setNomeEspecie={setNomeEspecie} onCreate={()=>setShowModalEspecies(tiposGerenciamentoModal.CRIAR)} onEdit={()=>setShowModalEspecies(tiposGerenciamentoModal.EDITAR)} onDetails={()=>setShowModalEspecies(tiposGerenciamentoModal.INFORMACOES)}/>
            <CardUsuarios onShowUsuarios={()=>setShowModalListaUsuarios(tiposGerenciamentoModal.INFORMACOES)} onEdit={(usuario)=>{setShowModalUsuarios(tiposGerenciamentoModal.EDITAR); setUsuario(usuario)}}/>
            <CardPatrocinadores onCreate={()=>setShowModalPatrocinadores(tiposGerenciamentoModal.CRIAR)} onEdit={(patrocinador)=>{setShowModalPatrocinadores(tiposGerenciamentoModal.EDITAR); setPatrocinador(patrocinador)}}/>
            {/* <CardOrganizacoes onCreate={()=>setShowModalOrganizacoes(tiposGerenciamentoModal.CRIAR)} onEdit={(organizacao)=>{setShowModalOrganizacoes(tiposGerenciamentoModal.EDITAR); setOrganizacao(organizacao)}}/> */}
            <CardApoiadores onCreate={()=>setShowModalApoiadores(tiposGerenciamentoModal.CRIAR)} onEdit={(apoiador)=>{setShowModalApoiadores(tiposGerenciamentoModal.EDITAR); setOrganizacao(apoiador)}}/>
            <CardFormularioApoiador/>
            <CardPrecoAssinatura/>
                      
            {/* <CardApoiadores/> */}
            </div>
          </section>

          <section>
            <BiomasModal bioma={bioma} setBioma={setBioma} tipo={showModalBiomas} onClose={()=>setShowModalBiomas(tiposGerenciamentoModal.NONE)}/> 
            <EspeciesModal especie={especie} setEspecie={setEspecie} nomeEspecie={nomeEspecie} setNomeEspecie={setNomeEspecie} tipo={showModalEspecies} onClose={()=>setShowModalEspecies(tiposGerenciamentoModal.NONE)}/> 
            <ListaUsuariosModal usuario={usuario} setUsuario={setUsuario} tipo={showModalListaUsuarios} openEditModal={()=>setShowModalUsuarios(tiposGerenciamentoModal.INFORMACOES)} onClose={()=>setShowModalListaUsuarios(tiposGerenciamentoModal.NONE)}/> 
            <UsuariosModal usuario={usuario} setUsuario={setUsuario} tipo={showModalUsuarios} onClose={()=>setShowModalUsuarios(tiposGerenciamentoModal.NONE)}/> 
            <PatrocinadoresModal patrocinador={patrocinador} setPatrocinador={setPatrocinador} tipo={showModalPatrocinadores} onClose={()=>setShowModalPatrocinadores(tiposGerenciamentoModal.NONE)}/> 
            <ApoiadoresModal apoiador={apoiador} setApoiador={setApoiador} tipo={showModalApoiadores} onClose={()=>setShowModalApoiadores(tiposGerenciamentoModal.NONE)}/> 
            {/* <OrganizacoesModal ={organizacao} onClose={()=>setShowModalOrganizacoes(tiposGerenciamentoModal.NONE)}/>  */}
          
          </section>

        </div>
      </Main>
    </SideBar>
  );
}

export default Home;